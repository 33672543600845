<script context="module" lang="ts">
	export const prerender = true;

	import Refresh from 'iconoir/icons/refresh-double.svg';
</script>

<div class="m-auto w-10 text-center">
	<img src={Refresh} alt="Refreshing Icon" class="anim-reload m-auto inline-block" />
</div>

<style>
	@keyframes rotate-animation {
		0% {
			transform: rotate(0deg);
		}
		50% {
			transform: rotate(180deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	.anim-reload {
		animation: rotate-animation 2s infinite cubic-bezier(0.3, 0.1, 0.4, 0.9);
	}
</style>
